const BaseService = require('@/services/BaseService')

const namespace = 'onboarding'

const information = (id, data, cb, errorCb) => {
    BaseService.put(namespace + '/' + id, data, cb, errorCb)
}

const detectIndustry = (id, data, cb, errorCb) => {
    BaseService.get(namespace + '/' + id + '/detect-industry', data, cb, errorCb)
}

const onboarded = (id, cb, errorCb) => {
    BaseService.put(namespace + '/' + id + '/onboard', {}, cb, errorCb)
}

export {
    information,
    detectIndustry,
    onboarded
}
