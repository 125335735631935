<template>
    <div class="onboarding-container full-screen">
        <div class="background-overlay full-screen"></div>
        <div class="background-image full-screen"></div>

        <div class="scroll-container full-screen">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                        <img class="logo" src="/images/opn/Generaxion_OPN_Bomærke_Hvid.svg"/>
                        <img class="logo" src="/images/opn/Generaxion_OPN_Logo-17.svg"/>
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .onboarding-container {
        color: white;
        background-color: #410b0c;

        .scroll-container {
            overflow-y: scroll;
        }

        .logo {
            width: 100px;
            margin: 40px 0;
        }

        .background-overlay {
            background: #12374c;
            background: linear-gradient(to bottom, rgba(18, 55, 76, 1), rgba(11, 34, 47, 1));
            background-size: 150% 150%;
        }
    }

    .full-screen {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    @media (max-width: 768px) {
        .onboarding-container {
            .logo {
                margin: 40px auto;
                display: block;
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import { mapGetters } from 'vuex'

    export default {
        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

          ...mapGetters('customer', {
            customer: 'getCustomer'
          })
        },

        beforeMount () {
          this.navigate()
        },

        methods: {
            navigate () {
                if (this.customer.onboarded) {
                    this.$router.push({ name: 'frontend.overview' })
                    return
                }
            }
        },

        components: {
            Card
        },
    }
</script>
