<template>
    <div class="onboarding-step-1">
        <div class="row">
            <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                <steps :step="1" textStep1="Vælg din <br> branche" :textStep2="textStep2" textStep3="Se <br> resultater"></steps>

            </div>
        </div>

        <div class="content">
            <meta-data></meta-data>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .onboarding-step-1 {
        h2 {
            margin-top: 38px;
        }

        p {
            font-size: 16px;
        }

        .content {
            margin-top: 40px;

        }
    }
</style>

<script>
    import MetaData from '@/app/onboarding/components/MetaData'
    import Steps from '@/app/onboarding/components/Steps.vue'

    import { mapGetters } from 'vuex'

    export default {
        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            flow() {
                const customer = this.customer

                if (!customer) {
                    return 'seo'
                }

                const source = customer.source

                switch (source) {
                    case 'facebook.seo':
                        return 'seo'
                        break

                    case 'facebook.benchmarks':
                        return 'benchmark'
                        break

                    case 'facebook.reviews':
                        return 'reviews'
                        break

                    default:
                        return 'seo'
                }
            },

            textStep2: function () {
                let text = ''

                switch (this.flow) {
                    case 'reviews':
                        text = 'Hent <br> anmeldelser'
                        break

                    case 'benchmark':
                        text = 'Forbind til <br> Google Analytics'
                        break

                    default:
                        text = 'Vælg <br> søgeord'
                        break
                }

                return text
            }
        },

        components: {
            MetaData,
            Steps,
        },
    }
</script>
