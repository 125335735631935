'<template>
    <div class="onboarding-steps">
        <div class="stepwizard">
            <div class="stepwizard-row">
                <div class="stepwizard-step" @click="navigateToStep1()">
                    <p v-html="textStep1"></p>
                    <span class="btn btn-default step">
                        <i class="fa fa-check" v-if="1 < step"></i>
                        <span v-else>1</span>
                    </span>
                </div>
                <div class="stepwizard-step" @click="navigateToStep2">
                    <p v-html="textStep2"></p>
                    <span class="btn btn-default step">
                        <i class="fa fa-check" v-if="2 < step"></i>
                        <span v-else>2</span>
                        </span>
                </div>
                <div class="stepwizard-step" @click="navigateToStep3">
                    <p v-html="textStep3"></p>
                    <span class="btn btn-default step">
                        <span>3</span>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    .onboarding-steps {
        a span {
            color: initial;
        }

        .step {
            color: #000;
        }

        .fa-check {
            color: #00c69e;
            text-shadow: #aaa 1px 1px 1px;
        }

        .stepwizard-step p {
            margin-top: 10px;
        }

        .stepwizard-row {
            display: table-row;
        }

        .stepwizard {
            display: table;
            width: 100%;
            position: relative;
        }

        .stepwizard-step .btn {
            opacity: 1 !important;
            filter: alpha(opacity=100) !important;
        }

        .stepwizard-row:before {
            top: 77px;
            left: 16.66%;
            right: 16.66%;
            bottom: 0;
            position: absolute;
            content: " ";
            height: 1px;
            background-color: white;

        }

        .stepwizard-step {
            display: table-cell;
            text-align: center;
            position: relative;
            width: 33%;
        }

        .step {
            color: #000;
            width: 30px;
            height: 30px;
            text-align: center;
            padding: 7px 0;
            font-size: 12px;
            line-height: 1.428571429;
            border-radius: 15px;
            background: white;
            border: none;
        }
    }
</style>

<script>
    export default {
        methods: {
          navigateToStep1 () {
            if (this.step < 1) {
              return
            }
            this.$router.push(this.linkStep1)
          },
          navigateToStep2 () {
            if (this.step < 2) {
              return
            }
            this.$router.push(this.linkStep2)
          },
          navigateToStep3 () {
            if (this.step < 3) {
              return
            }
            this.$router.push(this.linkStep3)
          }
        },
        props: {
            step: {
                type: Number,
                default: 1
            },
            textStep1: {
                type: String,
                default: ''
            },
            textStep2: {
                type: String,
                default: ''
            },
            textStep3: {
                type: String,
                default: ''
            },
            linkStep1: {
                type: Object,
                default: function () {
                    return {name: 'onboarding.step-1'}
                }
            },
            linkStep2: {
                type: Object,
                default: function () {
                   return {name: 'onboarding.step-2'}
                }
            },
            linkStep3: {
                type: Object,
                default: function () {
                    return {name: 'onboarding.step-3'}
                }
            }
        }
    }
</script>