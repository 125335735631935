<template>
  <div class="onboarding-step-3">
    <div class="row">
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
          <steps :step="3" textStep1="Vælg din <br> branche" :textStep2="textStep2"
                 textStep3="Se <br> resultater"></steps>

          <hr>
        </div>
      </div>

      <div class="content">
        <h2 class="text-center">Tillykke!</h2>

        <br>

        <template>
          <p class="text-center">
            Din forretningsprofil er nu succesfuldt oprettet og OP'N er klar til brug.
          </p>

          <div class="row">
            <div class="col-md-12">
              <animated-check type="check" color="white"></animated-check>
            </div>
          </div>

          <br>
          <br>

          <div class="text-center">
            <a @click="next" class="btn btn-inverse btn-lg">
              <span v-if="!isLoading">Start OP'N</span>
              <i class="fa fa-circle-o-notch fa-spin" v-else></i>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-step-3 {
  h2 {
    margin-top: 38px;
  }

  hr {
    margin-top: 45px;
    margin-bottom: 30px;
    opacity: 0.7;
  }

  p {
    font-size: 16px;
  }
}
</style>

<script>
import Steps from '@/app/onboarding/components/Steps.vue'
import AnimatedCheck from '@/app/layout/components/AnimatedCheck'

import {mapGetters} from 'vuex'

import MixpanelService from '@/services/mixpanel/MixpanelService'

const OnboardingService = require('@/services/onboarding/OnboardingService')

export default {
  data() {
    return {
      isLoading: false,
      isNavigation: false
    }
  },

  computed: {
    ...mapGetters('identity', {
      identity: 'getIdentity'
    }),
    ...mapGetters('customer', {
      customer: 'getCustomer'
    }),

    flow() {
      const customer = this.customer

      if (!customer) {
        return 'seo'
      }

      const source = customer.source

      switch (source) {
        case 'facebook.seo':
          return 'seo'
          break

        case 'facebook.benchmarks':
          return 'benchmark'
          break

        case 'facebook.reviews':
          return 'reviews'
          break

        default:
          return 'seo'
      }
    },

    textStep2: function () {
      let text = ''

      switch (this.flow) {
        case 'reviews':
          text = 'Hent <br> anmeldelser'
          break

        case 'benchmark':
          text = 'Forbind til <br> Google Analytics'
          break

        default:
          text = 'Vælg <br> søgeord'
          break
      }

      return text
    }
  },

  mounted() {
    this.onboarded(this.customer.id)
  },

  methods: {
    onboarded(customerId) {
      OnboardingService.onboarded(customerId)
    },

    next() {
      if (this.isNavigation) {
        return
      }

      this.isNavigation = true
      this.isLoading = true

      const customer = this.customer

      if (!customer) {
        return 'seo'
      }

      const source = customer.source

      this.$nextTick(() => {
        switch (source) {
          case 'facebook.seo':
            this.$router.push({
              name: 'frontend.reporting.seo',
              query: {
                'onboarding': true
              }
            })
            break

          case 'facebook.benchmarks':
            this.$router.push({
              name: 'frontend.reporting.visitors',
              query: {
                'onboarding': true
              }
            })
            break

          case 'facebook.reviews':
            this.$router.push({
              name: 'frontend.reviews',
              query: {
                'onboarding': true
              }
            })
            break

          default:
            this.$router.push({
              name: 'frontend.reporting.seo',
              query: {
                'onboarding': true
              }
            })
            return 'seo'
        }
      })

      MixpanelService.track('Onboarding - Complete')
    },
  },

  components: {
    Steps,
    AnimatedCheck
  }
}
</script>
