<template>
    <div class="onboarding-step-2">
        <div class="row">
            <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                <steps :step="2" textStep1="Vælg din <br> branche" :textStep2="textStep2" textStep3="Se <br> resultater"></steps>

                <hr>
            </div>
        </div>

        <div class="content">
            <transition-group name="slide-fade" mode="out-in">
                <!-- Help -->
                <div class="help" key="help" v-if="help">
                    <a @click="help = false" class="link-inverse">
                        <i class="fa fa-angle-left"></i> Tilbage
                    </a>

                    <h2 class="text-center">Få hjælp fra en medarbejder</h2>

                    <br>

                    <p class="text-center description">
                        Vi står klar til at hjælpe. Skriv til os og vi vender tilbage inden for én arbejdsdag.
                    </p>

                    <br>

                    <div class="text-center">
                        <a @click="help = false" class="link-inverse">Tilbage</a>
                    </div>
                </div>

                <!-- SEO -->
                <input-keywords v-if="flow === 'seo'" key="seo"></input-keywords>

                <!-- Reviews -->
                <div v-if="flow === 'reviews'" key="reviews">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h2 class="headline">Se dit omdømme</h2>

                            <br>

                            <p class="description">
                                Forbind OP'N med Trustpilot og Facebook for nemt og hurtigt at følge med i dit omdømme på internettet og samle alle dine anmeldelser - ét sted.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <br />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-xs-12 col-lg-6 col-md-6" v-if="hasHealthyIntegration('Trustpilot review')">
                            <trustpilot></trustpilot>
                        </div>

                        <div class="col-sm-12 col-xs-12" :class="{'col-lg-6 col-md-6': hasHealthyIntegration('Trustpilot review'), 'col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-lg-12 col-md-12': !hasHealthyIntegration('Trustpilot review')}">
                            <integration @help="help = true" help="no-page-change" :longHelpText="false" name="Facebook pages" service="facebook-pages" styling="landscape" :border="true" logo="/images/onboarding/facebook.svg" :headline="'Facebook Business Manager'" :description="'Forbind OP\'N med din virksomhedsside på Facebook'"></integration>
                        </div>

                        <div class="col-xs-12">
                            <div class="text-center">
                                <br />
                                <br />
                                <router-link :to="{name: 'onboarding.step-3'}" class="btn btn-inverse btn-lg">Næste</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Benchmarks -->
                <div v-if="flow === 'benchmark'" key="reviews">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h2 class="headline">Forbind med Google Analytics</h2>

                            <br>

                            <p class="description">
                                For at vise dig, hvor mange der besøger din hjemmeside, sammenligne dine resultater med andre og meget mere skal du herunder forbinde OP'N med Google Analytics. Alle dine data behandles fortroligt via en sikker forbindelse.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <br />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                            <integration @help="help = true" help="no-page-change" name="Google analytics" service="analytics" logo="/images/onboarding/google-analytics.svg" :headline="'Google Analytics'" :description="$t('onboarding.step2.googleAnalytics.description')"></integration>
                        </div>

                        <div class="col-xs-12">
                            <div class="text-center">
                                <br />
                                <br />
                                <router-link :to="{name: 'onboarding.step-3'}" class="btn btn-inverse btn-lg">Næste</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .onboarding-step-2 {
        min-height: 1200px;

        hr {
            margin-top: 45px;
            margin-bottom: 30px;
            opacity: 0.7;
        }

        p {
            font-size: 16px;
        }

        .skip-step {
            color: white;
            opacity: 0.5;
            margin-bottom: 50px;
            display: block;

            &:hover {
                opacity: 1;
            }
        }

        .link-inverse {
            color: white;
            opacity: 0.8;
            cursor: pointer;
        }

        .headline {
            margin: 0;
        }

        .content {
            margin-top: 25px;
        }

        .help {
            margin-bottom: 600px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Steps from '@/app/onboarding/components/Steps'
    import InputKeywords from '@/app/onboarding/components/InputKeywords'
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import Trustpilot from '@/app/onboarding/components/integrations/Trustpilot'

    import hasIntegration from '@/mixins/integrations/hasIntegration'

    import { mapGetters } from 'vuex'

    export default {
        mixins: [hasIntegration],

        data() {
            return {
                help: false
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            flow() {
                const customer = this.customer

                if (!customer) {
                    return 'seo'
                }

                const source = customer.source

                switch (source) {
                    case 'facebook.seo':
                        return 'seo'
                        break

                    case 'facebook.benchmarks':
                        return 'benchmark'
                        break

                    case 'facebook.reviews':
                        return 'reviews'
                        break

                    default:
                        return 'seo'
                }
            },

            textStep2: function () {
                let text = ''

                switch (this.flow) {
                    case 'reviews':
                        text = 'Hent <br> anmeldelser'
                        break

                    case 'benchmark':
                        text = 'Forbind til <br> Google Analytics'
                        break

                    default:
                        text = 'Vælg <br> søgeord'
                        break
                }

                return text
            }
        },

        components: {
            Steps,
            InputKeywords,
            Integration,
            Card,
            Trustpilot,
        },
    }
</script>
