<template>
	<div class="meta-data">
		<div class="row">
			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
				<h2 class="headline">{{$t('onboarding.step1.headline')}}</h2>

				<br>

				<p class="step-introduction">{{$t('onboarding.step1.description')}}</p>
			</div>

			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">

				<transition-group name="slide-fade">
					<warning type="error" v-for="(error, index) in errors" :key="index+1">
						<p v-if="typeof error === 'object'">{{error | capitalize}}</p>
						<p v-else>{{error | capitalize}}</p>
					</warning>
				</transition-group>

				<div class="create-form">
					<div>
						<div class="meta-label">{{$t('onboarding.step1.domain')}} <small class="text-muted pull-right">{{$t('onboarding.step1.example')}}</small></div>

						<div class="input-group">
							<span class="input-group-addon" :class="{error: validationFailed('domain')}">www.</span>

							<input v-model.trim="domain" type="text"
								   class="form-control domain-input"
								   :class="{error: validationFailed('domain')}"
								   @blur="checkDomain()"/>
						</div>

						<small v-if="isCheckingDomain">{{$t('onboarding.domain.checkingDomain')}} <loader :inline="true"></loader></small>

						<template v-else-if="domainMessage">
							<small>{{domainMessage}}</small>
							<br>

							<span v-if="redirectUrl" @click="useRedirectUrl" class="btn btn-inverse btn-filled btn-xs redirected-url-button">{{$t('onboarding.domain.useDomainInstead',{url: redirectUrl})}}</span>
						</template>
					</div>

					<br>

					<div>
						<div class="meta-label">{{$t('onboarding.step1.contactPerson')}}</div>

						<div class="input-group" style="width: 100%;">
							<input type="text" v-model="attName" class="form-control att-input" :class="{error: validationFailed('contactPerson')}" />
						</div>
					</div>

					<br>

					<div>
						<div class="meta-label">{{$t('onboarding.step1.phoneNumber')}}</div>

						<div class="input-group">
							<span class="input-group-addon" :class="{error: validationFailed('phoneNumber')}">+45</span>

							<input v-model="phoneNumber" type="text"
								   class="form-control domain-input"
								   :class="{error: validationFailed('phoneNumber')}"/>
						</div>
					</div>

					<br>

					<div>
						<span class="meta-label">{{$t('onboarding.step1.industry')}}</span>

						<multiselect :options="selectableCategories"
									 v-model="industry"
									 value="title"
									 label="title"
									 :placeholder="$t('onboarding.step1.chooseIndustry')"
									 selectLabel="Tryk enter for at vælge"
									 :class="{error: validationFailed('domain')}"
									 :selectLabel="$t('multiSelect.selectLabel')">
							<p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
						</multiselect>
					</div>

					<br>

					<div>
						<span class="meta-label">Kodeord</span>
						<input v-model="password" type="password" class="form-control">
					</div>

				</div>
			</div>

			<div class="col-xs-12">
				<br />
				<br />

				<div class="text-center">
					<button class="btn btn-inverse btn-lg" :class="{'disabled': !isFormComplete}" @click="save()">
						<span v-if="!isLoading">{{$t('onboarding.step1.button')}}</span>
						<i class="fa fa-circle-o-notch fa-spin" v-else></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss" scoped>
	@import '~@/assets/scss/_vars.scss';

	.meta-data {

		.create-form {
			.meta-label {
				margin-bottom: 5px;
				display: block;
			}

			small {
				margin-top: 3px;
			}
		}

		.step-introduction {
			margin-bottom: 20px;
		}

		.column {
			padding: 0 30px;
		}

		.text-muted {
			color: white;
			opacity: 0.7;
		}

		.att-input {
			width: inherit;
			padding: 6px 12px;
			border-radius: 5px;
		}

		.domain-input {
			padding-left: 0;
		}

		.form-control {
			//padding: 10px 12px 7px 0;
			&:focus {
				border: 1px solid #E3E3E3;
				border-left: none;
			}
		}

		.form-control.error {
			border: none;
		}

		.input-group {
			span.input-group-addon {

				&:not(.error) {
					padding-top: 7px;
				}
			}
		}

		span.input-group-addon {
			padding: 6px 0 6px 12px;
		}

		button {
			margin-bottom: 30px;
		}

		.headline {
			margin: 0;
		}

		.redirected-url-button {
			margin-top: 5px;
		}
	}
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import * as IndustryService from '@/services/customers/IndustryService'
    import * as CustomerService from '@/services/customers/CustomerService'
    import * as OnboardingService from '@/services/onboarding/OnboardingService'
    import Warning from '@/app/shared/global/warnings/Warning'

    import { mapGetters } from 'vuex'
    import Loader from "../../shared/components/Loader";

    const moment = require('moment');

    export default {
        data() {
            return {
                isLoading: false,
                industry: null,
                industries: [],
                domain: '',
                attName: '',
                phoneNumber: '',
                password: '',
                errors: [],
                slot: null,
                slots: [],
                domainMessage: null,
                isCheckingDomain: true,
                redirectUrl: null
            }
        },

        computed: {
			...mapGetters('identity', {
				identity: 'getIdentity'
			}),

			...mapGetters('customer', {
				customer: 'getCustomer'
			}),

            isFormComplete() {
                if (!this.industry || !this.domain || !this.phoneNumber || !this.attName || !this.password) {
                    return false
                }

                return this.industry !== '0';
            },

            selectableCategories() {
                let industries = this.industries.filter((industry) => {
                    return industry.parent === null
                })

                if (this.industry && this.industry.parent !== null) {
                    industries.unshift(this.industry)
                }

                return industries
            }
        },

        watch: {
            domain() {
                this.clearErrors()
            },

            phoneNumber() {
                this.clearErrors()
            },

            industry() {
                this.clearErrors()
            },

            slot() {
                this.clearErrors()
            }
        },

        created() {
            this.getIndustries()
        },

        mounted () {
            this.setCustomerInformation()
            this.detectIndustry()
        },

        methods: {
            clearErrors() {
                this.errors = []
            },

            setCustomerInformation() {
                const customer = this.customer

                this.domain = customer.domain
                this.phoneNumber = customer.phoneNumber

                if (customer.industry) {
                    this.industry = customer.industry
                }

                this.checkDomain()
            },

            getIndustries() {
                const options = {
                    includes: ['parent'],

                    sort: [
                        {
                            key: 'highlighted',
                            direction: 'DESC'
                        },
                        {
                            key: 'title',
                            direction: 'ASC'
                        }
                    ],

                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'visible',
                                    value: '1',
                                    operator: 'eq'
                                },
                            ]
                        }
                    ]
                }

                IndustryService.findAll(options, (response) => {
                    const body = response.data

                    this.industries = body.rows

                    this.hasLoaded = true
                }, (error) => {
                })
            },

            save() {
                if (!this.isFormComplete) {
                    return
                }

                this.clearErrors()

                this.isLoading = true

                const options = {
                    domain: 'http://' + this.domain,
                    phoneNumber: this.phoneNumber,
                    industry_id: this.industry.id,
                    password: this.password,
                    contactPerson: this.attName
                }

                OnboardingService.information(this.customer.id, options, (response) => { //edited to fix signup
                    this.isLoading = false
					this.$router.push({name: 'onboarding.step-2'})
                }, (error) => {
                    this.isLoading = false

                    const response = error.response
                    const data = response.data
                    const errors = data.errors

                    if (!errors.length) {
                        this.errors.push($t('onboarding.requestCall.unknownErrorTryAgain'))
                        return
                    }

                    const firstError = errors[0]

                    if (response.status === 422) {
                        this.errors = firstError.title
                    }
                })
            },

            validationFailed(name) {
                return this.errors.hasOwnProperty(name)
            },

            detectIndustry() {
                if (this.industry !== '0' && this.industry !== null) {
                    return
                }

                OnboardingService.detectIndustry(this.customer.id, {
                    includes: ['parent']
                }, (response) => {
                    const data = response.data
                    const suggestion = data.suggestion

                    if (!suggestion) {
                        return
                    }

                    const matches = this.industries.filter((industry) => {
                        return industry.id === suggestion.id
                    })

                    if (!matches.length) {
                        return
                    }

                    let industry = matches[0]

                    if (industry.parent) {
                        this.industry = industry.parent

                        return
                    }

                    this.industry = industry
                })
            },

            checkDomain() {
                this.clearErrors()

                this.isCheckingDomain = true
                this.domainMessage = null
                this.redirectUrl = null

                const domain = this.domain

                CustomerService.checkDomain({
                    domain: domain
                }, (response) => {
                    const data = response.data
                    let message

                    switch (data.state) {
                        case 'format_error':
                            message = $t('onboarding.domain.wrongFormat')
                            break

                        case 'no_connection':
                            message = $t('onboarding.domain.noConnection')
                            break

                        case 'no_redirects':
                            message = $t('onboarding.domain.isValid')
                            break

                        case 'is_redirected':
                            const lastRedirect = data.redirects.pop()

                            if (lastRedirect === domain) {
                                message = $t('onboarding.domain.isValid')
                                break
                            }

                            this.redirectUrl = lastRedirect
                            message = $t('onboarding.domain.isRedirectedTo', {url: lastRedirect})
                            break

                        default:
                            message = $t('onboarding.domain.unknownError')
                    }

                    this.domainMessage = message
                    this.isCheckingDomain = false
                }, (error) => {
                    const response = error.response
                    const errors = response.data

                    const firstError = errors.errors[0]
                    const title = Object.keys(firstError.title)[0]

                    if (title === 'domain') {
                        this.errors.push($t('onboarding.domain.domainAlreadyTaken'))
                    }

                    this.isCheckingDomain = false
                })
            },

            useRedirectUrl() {
                this.domain = this.redirectUrl

                this.checkDomain()
            }
        },

        components: {
            Loader,
            Card,
            Warning
        }
    }
</script>
