<template>
    <div class="onboarding-seo">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 suggestion-container">
                <h2 class="headline">Vælg tre søgeord</h2>

                <br>

                <p class="description">
                    Se dine placeringer på Google, når mulige kunder søger efter en virksomhed ligesom din.
                    Du vælger selv dine tre søgeord. Og du kan altid ændre dem igen senere.
                </p>

                <template v-if="keywordsToSave.length < 3">
                    <span v-for="suggestion in suggestedKeywords" v-if="!Object.values(keywords).includes(suggestion.keyword)" class="suggested-keyword" @click="addSuggestedKeyword(suggestion)">{{suggestion.keyword}}</span>
                </template>

            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="col-md-12 form-group">
                    <div class="input-group">
                        <input v-model="keywords['keyword1']" type="text" placeholder="F.eks. 'tandlæge thy" class="form-control">
                        <div @click="clearInput('keyword1')" class="input-group-addon">
                            <i class="pe-7s-close" v-if="keywords['keyword1'] && keywords['keyword1'].length"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-12  form-group">
                    <div class="input-group">
                        <input v-model="keywords['keyword2']" type="text" placeholder="F.eks. 'rodbehandling thy'" class="form-control">
                        <div @click="clearInput('keyword2')" class="input-group-addon">
                            <i class="pe-7s-close" v-if="keywords['keyword2'] && keywords['keyword2'].length"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-12  form-group">
                    <div class="input-group">
                        <input v-model="keywords['keyword3']" type="text" placeholder="F.eks. 'tandklinik thy'" class="form-control">
                        <div @click="clearInput('keyword3')" class="input-group-addon">
                            <i class="pe-7s-close" v-if="keywords['keyword3'] && keywords['keyword3'].length"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12">
                <div class="text-center">
                    <br />
                    <br />

                    <a @click="saveKeywords" class="btn btn-inverse btn-lg">
                        <span v-if="!isLoading">Gem</span>
                        <i class="fa fa-circle-o-notch fa-spin" v-else />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .onboarding-seo {
        .headline {
            margin: 0;
        }
    }

    .suggestion-container {
        margin-bottom: 20px;
    }

    .suggested-keyword {
        padding: 6px 12px 7px 12px;
        display: inline-block;
        margin-top: 8px;
        margin-right: 8px;
        border: 1px solid #ffffff;
        color: #ffffff;
        cursor: pointer;
        border-radius: 3px;
        font-weight: 400;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            color: #ffffff;
        }
    }

    .input-group-addon {
        cursor: pointer;
        padding-top: 10px;
        i {
            font-size: 18px;
        }
    }

    .form-group {
        padding: 0;
    }
</style>

<script>
    const KeywordService = require('@/services/seo/KeywordService')
    import MixpanelService from '@/services/mixpanel/MixpanelService'

    import { mapActions } from 'vuex'

    import SuggestionService from '@/services/seo/SuggestionService'

    export default {
        data() {
            return {
                keywords: {},
                isLoading: false,
                saved: 0,
                suggestedKeywords: []
            }
        },

        computed: {
            isSavingComplete() {
                return (this.saved === 3)
            },

            keywordsToSave() {
                return Object.values(this.keywords).filter((value) => {
                    return value.length > 0
                })
            }
        },

        watch: {
            isSavingComplete() {
                this.reloadIdentity()

                MixpanelService.track('Integration - Create', {'Integration type': 'SEO'})

                this.$router.push({name: 'onboarding.step-3'})
            }
        },

        mounted() {
            this.loadSuggestions()
        },

        methods: {
          ...mapActions('identity', {
            reloadIdentity: 'loadIdentity'
          }),

          clearInput(key) {
                this.$set(this.keywords, key, '')
            },

            loadSuggestions() {
                SuggestionService.findAll({
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'numberOfResults',
                                    operator: 'gt',
                                    value: 500
                                },
                                {
                                    key: 'numberOfResults',
                                    operator: 'lt',
                                    value: 1500000
                                }
                            ]
                        }
                    ]
                }, (response) => {
                    const data = response.data
                    this.suggestedKeywords = data.rows
                })
            },

            addSuggestedKeyword(suggestion) {
                if (!suggestion) {
                    return
                }

                const keyword = suggestion.keyword

                MixpanelService.track('Onboarding - Keywords - Suggestion - Select', {keyword: keyword})

                for(let i = 1; i < 4; i++) {
                    if (!this.keywords['keyword' + i]) {
                        this.$set(this.keywords, 'keyword' + i, keyword)
                        return
                    }
                }
            },

            saveKeywords() {
                this.isLoading = true

                const keywords = this.keywordsToSave

                for (let i = 0; i < 3; i++) {
                    KeywordService.create({keyword: keywords[i]}, () => {
                        this.saved++

                        eventHub.$emit('keyword.created')

                        this.isLoading = !this.isSavingComplete
                    }, () => {
                        this.saved++

                        this.isLoading = !this.isSavingComplete
                    })
                }
            }
        },
    }
</script>
