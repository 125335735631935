<template>
    <div class="welcome text-center">
        <div class="row">
            <div class="col-md-8 col-md-push-2">
                <h2>Velkommen!</h2>

                <br>

                <p>Inden vi begynder, skal du oprette en bruger i OP'N til din virksomhed.
                   Følg de næste tre simple trin for at oprette din profil.</p>

                <br>

                <router-link :to="{name: 'onboarding.step-1'}" class="btn btn-inverse">Kom i gang</router-link>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    .welcome {

    }
</style>

<script>
    export default {

    }
</script>